.find-service-center-tabs {
    &__content {
        padding: pxToRem(20) 0;
    }

    &__list {
        display: flex;
        flex-wrap: nowrap;
        margin-left: 
            calc(((100vw - 100%) - #{pxToRem(12)}) * -1)
        ;
        width: 100vw;
    }

    &__list-item {
        width: 100%;
    }
    
    &__list-link.nav-link {
        background-color: $swatch-grey-lightest;
        border-radius: 0;
        color: $swatch-grey-dark;
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(30);
        padding: pxToRem(15) 0;
        text-decoration: underline;
        width: 100%;
        
        &.active {
            background-color: $swatch-white;
            text-decoration: none;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .find-service-center-tabs {
        border: pxToRem(1) solid $swatch-grey-lightest;
        
        &__list {
            margin-left: 0;
            width: 100%;
        }

        &__list,
        &__list-link.nav-link {
            border: 0;
        }
        
        &__list-link.nav-link {
            font-size: pxToRem(18);
        }

        &__content {
            padding: 0;
        }

        .tab-pane--list {
            padding: pxToRem(40)  pxToRem(90) pxToRem(30) pxToRem(100);
        }

        .tab-pane--map {
            padding: pxToRem(40) pxToRem(40) pxToRem(35);
        }
    }
}
