.find-service-center-nearest {
    border: pxToRem(1) solid $swatch-green-light;
    border-top-left-radius: pxToRem(5);
    border-top-right-radius: pxToRem(5);
    display: none;
    padding: pxToRem(40) pxToRem(35) pxToRem(50) pxToRem(25);
    margin: pxToRem(25) 0 pxToRem(35);

    &.active {
        display: flex;
    }
    
    &::before {
        background-image: url($icon-path + 'google-pin-green.svg');
        content: '';
        display: block;
        height: pxToRem(25);
        width: pxToRem(18);
    }
    
    &__content {
        padding-left: pxToRem(15);
        width: calc(100% - #{pxToRem(23)});
    }
    
    &__town {
        font-weight: $font-weight-bold;
        margin-bottom: pxToRem(5);
    }
    
    &__town,
    &__address {
        font-size: pxToRem(16);
        line-height: pxToRem(20);
    }
    
    &__summary {
        margin-bottom: 0;
    }
    
    &__link[href] {
        color: $swatch-green-darker;
        font-weight: $font-weight-bold;
    }
}

@media screen and (min-width: $md) {
    .find-service-center-nearest {
        margin: pxToRem(60) 0;

        &__town,
        &__address {
            font-size: pxToRem(18);
        }
    }
}
