@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');

/*
--------------------
Plugins
--------------------
*/

@import "node_modules/normalize.css/normalize";
@import "node_modules/bootstrap/scss/bootstrap.scss";

/*
--------------------
Design helpers
--------------------
*/

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "imports/variables.scss";
@import "imports/functions.scss";
@import "imports/mixins.scss";
@import "imports/placeholders.scss";
@import "imports/lib.scss";
@import "imports/typography.scss";

/*
--------------------
Utilities
--------------------
*/

@import "imports/utilities.scss";

/*
--------------------
General
--------------------
*/

@import "imports/base.scss";
@import "imports/print.scss";

/*
--------------------
Modules
--------------------
*/
@import "imports/kms.scss";
@import "../modules/_global/css/global.scss";
@import "../modules/accordion/css/global.scss";
@import "../modules/alert/css/global.scss";
@import "../modules/banner-tabs/css/global.scss";
@import "../modules/banner/css/global.scss";
@import "../modules/contact-form/css/global.scss";
@import "../modules/cta-image/css/global.scss";
@import "../modules/cta-link-block/css/global.scss";
@import "../modules/cta-panel-narrow/css/global.scss";
@import "../modules/feedback-form/css/global.scss";
@import "../modules/find-service-center-map/css/global.scss";
@import "../modules/find-service-center-nearest/css/global.scss";
@import "../modules/find-service-center-tabs/css/global.scss";
@import "../modules/GlobalFooter/css/global.scss";
@import "../modules/GlobalHeader/css/global.scss";
@import "../modules/govt-cta-cards/css/global.scss";
@import "../modules/home-scroll/css/global.scss";
@import "../modules/key-info-cta/css/global.scss";
@import "../modules/key-info/css/global.scss";
@import "../modules/more-information/css/global.scss";
@import "../modules/on-this-page/css/global.scss";
@import "../modules/page-utilities/css/global.scss";
@import "../modules/related-information/css/global.scss";
@import "../modules/search-autocomplete/css/global.scss";
@import "../modules/search-cards/css/global.scss";
@import "../modules/search-header/css/global.scss";
@import "../modules/search-pagination/css/global.scss";
@import "../modules/services-accordions/css/global.scss";
@import "../modules/services-cards/css/global.scss";
@import "../modules/sub-category-listing/css/global.scss";
@import "../modules/time-taken/css/global.scss";

body {
  font-family: 'Nunito', sans-serif;
}

body.govt-tas {
  font-family: 'Inter', sans-serif;
}

main.main > .container-fluid > .row > .pt-5 .stretch:first-child {
  margin-top: 0;
  padding-top: 0;
}

.kms-accordion-button:focus-visible {
    outline: 2px solid $swatch-black; 
    outline-offset: 0.2rem;
}
