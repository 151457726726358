.accordions {
    padding: pxToRem(30) 0;
}

.accordion {
    background-color: $swatch-white;
    border-top: pxToRem(1) solid $swatch-grey-border-card;
    
    &:last-child {
        border-bottom: pxToRem(1) solid $swatch-grey-border-card;
    }

    &__header {
        align-items: center;
        background-color: #ffffff;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    
    }

    &__button,
    &__button.collapsed {
        font-size: pxToRem(25.6);
        font-weight: $font-weight-bold;
        line-height: pxToRem(28);
        letter-spacing: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
        
        &:after {
            background: url($icon-path + 'accordian-closed.svg') center center no-repeat;
            background-size: contain;
            display: inline-block;
            height: pxToRem(15);
            width: pxToRem(15);
        }
        
        &.collapsed {
            color: $swatch-grey-darkest;
        }
        
        &:not(.collapsed) {
            background: $swatch-white;
            box-shadow: none;
            color: $swatch-grey-darkest;

            &:after {
                background: url($icon-path + 'accordian-closed.svg') center center no-repeat;
                background-size: contain;
                height: pxToRem(15);
                width: pxToRem(15);
            }
        }

        &:hover {
            box-shadow: none;
            outline: 0;
            -webkit-appearance: none;
        }
    }

    &__body {
        padding: pxToRem(20) 0 pxToRem(30);

        p, a {
            letter-spacing: 0;
            line-height: pxToRem(18);
        }
    
        p {
            color: $swatch-grey-darkest;
        }
        
        a {
            font-weight: $font-weight-semibold;
        }
    }

    @include focus-state($swatch-black);
}

.accordion-service-centers {
    border-color: $swatch-grey-lightest;

    &:first-child {
        border-top: 0;
    }

    &:last-child {
        border-bottom: 0;
    }
    
    button.accordion__button {
        padding: pxToRem(20);
        
        &:not(.collapsed) {
            font-weight: $font-weight-semibold;
        }
    }
    
    .accordion__body {
        padding: pxToRem(30) pxToRem(40) pxToRem(25);
    }
    
    &__item {
        display: flex;
        padding: pxToRem(15) pxToRem(15) pxToRem(15) pxToRem(30);
        
        &::before {
            background-image: url($icon-path + 'google-pin-green.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: pxToRem(25);
            margin: 0  pxToRem(20) 0 pxToRem(-15);
            width: pxToRem(18);            
        }
    }
    
    &__content {
        width: calc(100% - #{pxToRem(18)});
    }
    
    &__town {
        font-size: pxToRem(18);
        font-weight: $font-weight-bold;
        margin-bottom: pxToRem(5);
    }

    &__town,
    &__address {
        line-height: pxToRem(20);
    }
    
    &__summary {
        margin-bottom: 0;
        
        span {
            line-height: pxToRem(20);
        }
    }

    &__link[href] {
        font-weight: $font-weight-bold;
    }
}

// KMS Accordion Styles
.kms-accordion-button {
    align-items: center;
    background-color: transparent;
    border: none;
    border-top: pxToRem(1) solid $swatch-grey-darkest;
    color: $swatch-grey-light;
    cursor: pointer;
    display: flex;
    font-size: pxToRem(25.6);
    font-weight: $font-weight-bold;
    justify-content: space-between;
    letter-spacing: 0;
    line-height: pxToRem(28);
    outline: none;
    padding: pxToRem(16) 0;
    text-align: left;
    width: 100%;
    
    &:hover {
        box-shadow: none;
        outline: 0;
        -webkit-appearance: none; }
    
    //&:focus {
        //outline: 2px solid $swatch-access-blue;
        //outline-offset: 0;
        //box-shadow: none;
    //}
    
    &:last-child {
        border-bottom: pxToRem(1) solid $swatch-grey-darkest;
    }
    
    &::after {
        border: pxToRem(2) solid $swatch-grey-darkest;
        border-bottom: 0;
        border-right: 0;
        content: '';
        display: block;
        height: pxToRem(10);
        transform: rotate(225deg);
        transition: 0.2s transform ease-in;
        width: pxToRem(10);
    }

    &.active:after {
        transform: rotate(45deg);
    }
}

.kms-accordion-button.active ~ .kms-accordion-dropdown * {
    visibility: visible;
}

.kms-accordion-dropdown {
    background-color: $swatch-white;
    margin-bottom: pxToRem(1);
    max-height: 0;
    overflow: hidden;
    padding: 0 pxToRem(18);
    transition: max-height 0.2s ease-out;
    
    * {
        visibility: hidden;
    }
    
    & ~ p {
        margin-top: pxToRem(20);
    }
    
    ul {
        margin-top: pxToRem(10);
    }
}
