.contact-form {
    textarea:focus,
    input.sq-form-field:not([type=checkbox]):not([type=radio]):focus,
    select:focus {
        border-color: $swatch-green-darker;
        box-shadow: 0 0 0 pxToRem(4) rgba($swatch-green-darker, 0.25);
    }

    &__error {
        color: $swatch-red-warn;
        font-size: pxToRem(16);
        margin-top: 0.5rem;

        span {
            font-size: pxToRem(20);
            margin-right: 0.5rem;
        }
    }

    &__error-list {
        background-color: $swatch-red-warn-bg;
        padding: pxToRem(20);
        box-sizing: border-box;
        border-left: pxToRem(6) solid $swatch-red-warn;
        margin-bottom: pxToRem(50);
        color: $swatch-red-warn;

        h3 {
            margin-bottom: pxToRem(20);
        }

        ul {
            padding-left: pxToRem(20);
            list-style: none;
            line-height: pxToRem(45);
            line-height: 1rem;
            p {
                margin-bottom: 0.75rem;
            }
        }
    }

    &__field-warning {
        color: $swatch-red-warn;

        &:is(:focus, :active) {
            color: $swatch-red-warn;
            outline: pxToRem(3) solid $swatch-red-warn;
            outline-offset: 0.2rem;
        }

        &:hover {
            color: $swatch-red-warn;
        }
    }

    label,
    legend {
        font-weight: $font-weight-semibold;
        margin-bottom: pxToRem(5);
        font-size: pxToRem(18);
    }

    .sq-form-question-answer ul li {
        align-items: center;
        display: flex;
        padding-left: 0;
    }

    .sq-form-question-answer ul li input[type=radio] {
        margin-right: pxToRem(10);
    }

    [type="radio"] {
        margin: 0;
        position: relative;

        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }

        &::before {
            background-color: $swatch-white;
            border: pxToRem(1) solid $swatch-grey-dark;
            border-radius: 50%;
            content: '';
            display: block;
            height: pxToRem(22);
            max-height: pxToRem(22);
            max-width: pxToRem(22);
            position: absolute;
            transform: translate(#{pxToRem(-1)}, #{pxToRem(-5)});
            width: pxToRem(22);
        }
        
        &:checked::after {
            background-color: $swatch-green-light;
            border-radius: 50%;
            content: '';
            display: block;
            height: pxToRem(14);
            position: absolute;
            transform: translate(#{pxToRem(3)}, #{pxToRem(-1)});
            width: pxToRem(14);
            z-index: 1;
        }
    }
    
    input[type="submit"] {
        background-color: $swatch-green-dark;
        border-color: $swatch-green-dark;
        
        &:focus,
        &:hover {
            background-color: darken($swatch-green-dark, 10%);
            border-color: darken($swatch-green-dark, 10%);
        }
    }
    
    //&__email,
    //&__phone,
    //&__address {
        //max-height: 0;
        //opacity: 0;
        //overflow: hidden;
        
    //     &.active {
    //         max-height: none;
    //         opacity: 1;
    //         overflow: visible;
    //     }
    // }

    button:focus, 
    a:focus, 
    input:focus,
    textarea:focus {
        /* bootstrap override */
        outline: 2px solid $swatch-black !important; 
    }
}
