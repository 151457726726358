.time-taken {
    margin: pxToRem(30) 0;

    table {
        background: rgba($swatch-green-darker, 0.02);
        border: pxToRem(1) solid $swatch-green-darker;
        width: 100%;
    }

    tr {
        display: flex;
        padding: pxToRem(20);
    }
    
    td {
        align-items: center;
        display: flex;
        font-size: pxToRem(16);
        font-weight: 700;
        line-height: pxToRem(18);
        letter-spacing: 0;
    }
    
    img {
        margin-right: pxToRem(15);
    }
}

@media screen and (min-width: $md) {
    .time-taken {
        td {
            font-size: pxToRem(18);
        }
    }
}