.cta-link-block {
    background-color: $swatch-white-off;
    padding: pxToRem(50) 0 pxToRem(90);
    
    &__heading {
        font-size: pxToRem(25);
        font-weight: $font-weight-black;
        line-height: pxToRem(35);
        letter-spacing: -0.02em;
        margin-bottom: pxToRem(35);
    }
    
    &__link {
        align-items: center;
        background-color: $swatch-white;
        border: pxToRem(1) solid $swatch-grey-lightest;
        color: $swatch-black;
        display: flex;
        font-size: pxToRem(18);
        font-weight: $font-weight-black;
        justify-content: space-between;
        line-height: pxToRem(24);
        letter-spacing: 0;
        margin-bottom: pxToRem(20);
        padding: pxToRem(100) pxToRem(35) pxToRem(45);
        text-decoration: none;
        transition: 0.2s color ease-in, 0.2s background-color ease-in;
        
        &::after {
            background-image: url($icon-path + 'chevron-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 0;
            display: inline-block;
            height: pxToRem(17);
            width: pxToRem(10);
        }
        
        &:focus,
        &:hover {
            background-color: $swatch-green-dark;
            color: $swatch-white;
            
            &::after {
                background-image: url($icon-path + 'chevron-right-white.svg');
            }
        }
        
        &:last-child {
            margin: 0;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $lg) {
    .cta-link-block {
        padding: pxToRem(70) 0;

        &__heading {
            font-family: Nunito;
            font-size: pxToRem(30);
            font-weight: $font-weight-black;
            line-height: pxToRem(41);
            letter-spacing: 0;
            width: 30%;
        }
        
        &__links {
            align-items: stretch;
            display: flex;
            justify-content: flex-end;
            width: 60%;
        }
        
        &__link {
            box-shadow: pxToRem(0) pxToRem(4) pxToRem(60) 0 rgba($swatch-black, 0.07);
            height: 100%;
            margin: 0 pxToRem(25) 0 0;
            padding: pxToRem(100) pxToRem(40) pxToRem(45);
            width: 50%;
            
            &::after {
                content: '';
            }
        }
    }
}
