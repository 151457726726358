@media only print {
    * {
        color: $swatch-black !important;
    }

    .alert,
    .feedback-form,
    .footer,
    .banner__breadcrumbs,
    .header-flyout,
    .header-top,
    .header-nav-buttons,
    .banner .position-relative,
    .banner-tabs {
        display: none !important;
    }
    
    .collapse {
        display: block !important;
    }
    
    .banner__content {
        margin: 0 !important;
        padding: 0 !important;
    }
    
    .stretch {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    
    .services-accordion-dropdown,
    .services-card-dropdown {
        height: auto !important;
    }
    
    .services-card-dropdown__list {
        visibility: visible !important;
    }

    .stPanel {
        max-height: none !important;
    }
}