// This is the home banner
.govt-banner,
.banner {
    padding: pxToRem(20) 0;
    position: relative;
    
    &::before,
    &::after {
        background: $banner-gradient;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    
    &::after {
        background-image: url($icon-path + 'homebanner-diagonals.svg');
        background-position: center;
        background-size: cover;
    }
    
    &__content {
        background-color: $swatch-green-dark;
        padding: pxToRem(30) pxToRem(15) pxToRem(30);
        position: relative;
        z-index: 1;

        &::before {
            background-color: $swatch-green-dark;
            content: '';
            display: block;
            height: 100%;
            left: pxToRem(-12);
            position: absolute;
            top: 0;
            width: pxToRem(12);
        }
    }
    
    &__heading {
        color: $swatch-white;
        font-size: pxToRem(35);
        font-weight: $font-weight-black;
        letter-spacing: 0em;
        line-height: 40px;
        margin-bottom: 0;
    }
    
    &__subheading {
        color: $swatch-white;
        font-size: pxToRem(25);
        letter-spacing: 0em;
        line-height: 40px;
        margin-bottom: 0;
    }
    
    &__feedback {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        height: 100%;
        position: relative;
        width: 100%;
        z-index: 1;
    }
    
    &__feedback-link {
        align-items: center;
        color: $swatch-white;
        background-color: $swatch-green-darker;
        display: flex;
        font-size: pxToRem(14);
        height: pxToRem(60);
        letter-spacing: 0;
        min-height: pxToRem(60);
        min-width: pxToRem(112);
        line-height: pxToRem(30);
        padding: pxToRem(8) pxToRem(12) pxToRem(20) pxToRem(8);
        text-align: center;
        text-decoration: none;
        transform: rotate(-90deg) translateY(calc(100% - #{pxToRem(18)}));
        transition: 0.5s background-color ease-in;
        width: pxToRem(112);
        
        &:focus,
        &:hover {
            color: $swatch-white;
            background-color: lighten($swatch-green-darker, 5%);
        }
        
        &::before {
            background-image: url($icon-path + 'feedback-thumbs.svg');
            content: '';
            display: inline-block;
            height: pxToRem(24);
            margin-right: pxToRem(8);
            vertical-align: middle;
            transform: rotate(90deg);
            width: pxToRem(24);
        }
    }
    
    &__image {
        display: none;
        height: 100%;
        right: pxToRem(-12);
        object-fit: cover;
        position: absolute;
        width: 100%;
        z-index: 0;
        
        &.center {
            object-position: center;
        }
        
        &.top {
            object-position: top;
        }
        
        &.right {
            object-position: right;
        }
        
        &.bottom {
            object-position: bottom;
        }
        
        &.left {
            object-position: left;
        }
    }

    @include focus-state;
}

@media screen and (min-width: $sm) {
    .govt-banner,
    .banner {
        padding: 0;

        &::before,
        &::after {
            width: calc(((100% / 12) * 7) + #{pxToRem(24)});
        }

        &__content {
            background-color: transparent;
            margin: pxToRem(60) 0 pxToRem(95);
            padding: pxToRem(35) pxToRem(35) pxToRem(45) 0;

            &::before {
                left: 0;
                transform: translateX(
                    calc(((100vw / 12) + pxToRem(12)) * -1)
                );
                width: calc(100% + ((100vw / 12) + pxToRem(12)));
            }
        }
        
        &__heading {
            font-size: pxToRem(50);
            font-weight: 800;
            line-height: pxToRem(54);
            margin-bottom: 0;
            position: relative;
            z-index: 1;
        }
        
        &__subheading {
            font-size: pxToRem(50);
            font-weight: 400;
            line-height: pxToRem(54);
            position: relative;
            z-index: 1;
        }
        
        &__feedback-link {
            z-index: 1;
        }
        
        &__image {
            display: block;
        }
    }
}
