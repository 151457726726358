.search-pagination {
    padding: pxToRem(20) 0;
    position: relative;
    
    &__list {
        display: flex;
        justify-content: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    
    &__list-item {
        margin-right: pxToRem(10);
        
        &--prev {
            margin-right: pxToRem(30);
        }
        
        &--next {
            margin-left: pxToRem(30);
            margin-right: 0;
        }
        
        &:nth-last-child(2) {
            margin-right: 0;
        }
        
        &.active .search-pagination__list-link {
            color: $swatch-green-darker;
            font-weight: $font-weight-bold;
            text-decoration: none;
        }
    }
    
    &__list-item--next,
    &__list-item--prev,
    &__list-item--next .search-pagination__list-link,
    &__list-item--prev .search-pagination__list-link {
        color: $swatch-green-darker;
        font-weight: $font-weight-bold;
        
        &:focus,
        &:hover {
            color: $swatch-green-darker;
            font-weight: $font-weight-bold;
        }
    }
    
    &__list-link {
        color: $swatch-black;
        
        &:focus,
        &:hover {
            color: $swatch-black;
        }
        
        span {
            text-decoration: none;
        }
    }
}

@media screen and (min-width: $md) {
    .search-pagination {
        padding: pxToRem(50) 0 pxToRem(40);
    }
}