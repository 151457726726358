p,
a,
li {
    font-size: pxToRem(18);
}

a {
    word-break: break-word;
    color: $swatch-grey-light;
    
    &:focus,
    &:hover {
        color: $swatch-green-darker;
    }
}

h1 {
    font-size: pxToRem(48);
}

h2 {
    font-size: pxToRem(30);
    font-weight: $font-weight-black;
    line-height: 1.2;
}

h3 {
    font-size: pxToRem(25.6);
    font-weight: $font-weight-bold;
}

h4 {
    font-size: pxToRem(18.4);
    font-weight: $font-weight-bold;
}

h5 {
    font-size: pxToRem(16);
    font-weight: $font-weight-bold;
}

h6 {
    font-size: pxToRem(12);
}

body.govt-tas {
    a {
        color: $swatch-govt-blue-3;
        font-weight: $font-weight-semibold;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $swatch-govt-blue-3;
    }
    
    h2 {
        font-weight: $font-weight-semibold;
    }
    
    h3 {
        font-weight: $headings-font-weight;
    }
}