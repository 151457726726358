.header-top {
    background-color: $swatch-grey-dark;
    padding: pxToRem(10);

    &__link {
        align-items: center;
        color: $swatch-white;
        display: flex;
        font-size: pxToRem(16);
        font-weight: $font-weight-semibold;
        line-height: pxToRem(30);
        letter-spacing: 0em;

        &:hover,
        &:focus {
            color: $swatch-white;
        }

        &::before {
            background-image: url('~src/images/external-link.png');
            background-size: cover;
            content: '';
            display: inline-block;
            height: pxToRem(13.5);
            margin-right: pxToRem(10);
            vertical-align: middle;
            width: pxToRem(13.5);
        }

        &--language {
            text-decoration: none;

            &::before {
                background-image: url($icon-path + 'language.svg');
                height: pxToRem(17.5);
                width: pxToRem(17.5);
            }
        }
    }

    @include focus-state;
}
