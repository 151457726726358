.search-header {
    padding: pxToRem(25) 0 pxToRem(55);
    position: relative;
    
    &__search-heading {
        font-size: pxToRem(24);
        font-weight: $font-weight-black;
        line-height: pxToRem(35);
        letter-spacing: -0.02em;
        margin-bottom: pxToRem(20);
    }

    &__search-form {
        align-items: center;
        align-content: stretch;
        align-self: stretch;
        background-color: aliceblue;
        border: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: pxToRem(15);
    }
    
    &__search-field {
        align-self: stretch;
        display: flex;
        flex: 1;
        position: relative;
    }
    
    .icon-search {
        position: absolute;
        top: 50%;
        left: 0;
        width: pxToRem(16);
        height: pxToRem(16);
        background: url('~src/modules/SVGs/search-icon-green.svg') center center no-repeat;
        background-size: cover;
        transform: translate(pxToRem(9), pxToRem(-7));
    }

    .icon-clear {
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 0;
        width: pxToRem(14);
        height: pxToRem(14);
        background: url('~src/modules/SVGs/close-icon-green.svg') center center no-repeat;
        background-size: cover;
        transform: translate(pxToRem(-10), pxToRem(-7));
        
        &.disabled {
            display: none;
        }
    }

    input {
        border: pxToRem(1) solid $swatch-green-darker;
        box-shadow: none;
        color: $swatch-green-darkest;
        font-size: pxToRem(14);
        font-weight: $font-weight-bold;
        outline: none;
        letter-spacing: 0em;
        line-height: pxToRem(18);
        padding: pxToRem(15) pxToRem(35);
        text-align: left;
        width: 100%;

        &::-webkit-input-placeholder, 
        &::-moz-placeholder, 
        &:-ms-input-placeholder,
        &::placeholder {
            color: $swatch-green-darker;
        }

        &:active,
        &:focus {
            border: pxToRem(1) solid $swatch-gradient-green-1;
        }
    }
    
    .tt-menu {
        width: calc(100% + #{pxToRem(96)});
    }
    
    &__results-text {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: pxToRem(30);
        color: $swatch-grey-dark;
        margin-bottom: 0;
    
        span {
            color: $swatch-green-darker;
            font-weight: 700;
        }
    }

    &__search-submit {
        background-color: $swatch-green-darker;
        border: 0;
        color: $swatch-white;
        font-size: pxToRem(15);
        font-weight: 400;
        letter-spacing: 0em;
        line-height: pxToRem(30);
        padding: pxToRem(10) pxToRem(25);
        
        &:focus,
        &:hover {
            opacity: 0.8;
        }
    }
    
    &__breadcrumbs {
        margin-bottom: pxToRem(30);
        
        .breadcrumb {
            margin-bottom: 0;
        }
    
        .breadcrumb-item,
        a {
            color: $swatch-grey-dark;
            font-size: pxToRem(14);
            font-weight: $font-weight-bold;
            line-height: pxToRem(30);
            letter-spacing: 0;
            text-decoration: underline;
        }
    }
}


@media screen and (min-width: $md) {
    .search-header {
        padding: pxToRem(55) 0 pxToRem(90);
    }
}
