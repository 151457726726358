.key-info-cta {
    #actionStep {
        display: none;
    }

    &__content {
        background-color: $swatch-grey-lightest;
        padding: pxToRem(35) pxToRem(30);
    }
    
    h4 {
        font-size: pxToRem(15);
        font-weight: $font-weight-normal;
        line-height: pxToRem(18);
        letter-spacing: 0;
        margin-bottom: pxToRem(30);
    }
    
    a {
        background-color: $swatch-green-darker;
        color: $swatch-white;
        display: inline-block;
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(30);
        padding: pxToRem(10);
        right: 0;
        text-decoration: none;
        top: 50%;
        
        &:focus,
        &:hover {
            opacity: 0.8;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .key-info-cta {
        &__content {
            padding: pxToRem(30) pxToRem(70) pxToRem(30) pxToRem(40);
            
            .row .col-6:nth-child(2) {
                order: 3;
            }
            
            .row .col-6:nth-child(3) {
                order: 2;
            }
            
            h4 {
                font-size: pxToRem(18);
            }
            
            a {
                font-size: pxToRem(18);
                position: absolute;     
                transform: translateY(-50%);
            }
        }
    }
}
