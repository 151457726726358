.twitter-typeahead {
    position: static !important;
    width: 100%;
}

.tt-menu {
    background: $swatch-white;
    border: pxToRem(1) solid $swatch-grey-lightest;
    left: 0;
    margin-top: pxToRem(3);
    padding: pxToRem(30) pxToRem(40) pxToRem(30) pxToRem(30);
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;

    &.tt-open {
        display: block;
    }

    .tt-suggestion {
        position: relative;
        
        &::after {
            background-image: url($icon-path + 'chevron-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            height: pxToRem(17);
            position: absolute;
            right: pxToRem(-15);
            top: 50%;
            transform: translateY(-50%);
            width: pxToRem(10);
        }

        &:focus::after,
        &:hover::after {
            background-image: url($icon-path + 'chevron-right-green.svg');
        }
    }

    .tt-selectable {
        color: $swatch-grey-darkest;
        cursor: pointer;
        padding: pxToRem(5) 0;
        width: calc(100% - #{pxToRem(15)});
    }

    .tt-highlight {
        color: $swatch-green-darker;
        font-size: pxToRem(16);
    }
}