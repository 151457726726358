.services-card-dropdown {
    background-color: $swatch-white;
    border: pxToRem(2) solid transparent;
    border-top: 0;
    height: 0;
    margin-bottom: pxToRem(10);
    overflow: hidden;
    padding: 0;
    transform: translateY(pxToRem(-10));
    transition: height 0.2s ease-in;

    &__list {
        list-style-type: none;
        margin: 0;
        padding: pxToRem(10) pxToRem(60) pxToRem(30) pxToRem(30);
        visibility: hidden;
    }

    &__list-item {
        margin-bottom: pxToRem(10);
    }

    &__list-link {
        align-items: center;
        color: $swatch-black;
        display: flex;
        font-size: pxToRem(15);
        font-weight: $font-weight-semibold;
        letter-spacing: 0;
        line-height: pxToRem(21);
        padding-right: pxToRem(10);
        position: relative;
        justify-content: space-between;
        text-decoration: none;
        
        &:focus,
        &:hover {
            color: $swatch-black;
            
            &::after {
                background-image: url($icon-path + 'chevron-right-green.svg');
            }
        }
        
        &::after {
            background-image: url($icon-path + 'chevron-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: pxToRem(12);
            position: absolute;
            right: 0;
            width: pxToRem(7);
        }
    }

    @include focus-state($swatch-black);
}

// Styling for the dropdown when the card is active.
.services-card {
    &.active ~ .services-card-dropdown {
        border-color: $swatch-green-lighter;
        position: relative;
        z-index: 1;
        
        .services-card-dropdown__list {
            visibility: visible;
        }
    }
}

@media screen and (min-width: $lg) {
    .services-card {
        &.active ~ .services-card-dropdown {
            border-top: pxToRem(2) solid $swatch-green-lighter;
            
            & > .services-card-dropdown__list {
                padding: pxToRem(45) pxToRem(55) pxToRem(35);
            }
        }
        
        &.second ~ .services-card-dropdown {
            transform: translateX(calc(((100% / 3) + #{pxToRem(6)}) * -1));
        }
        
        &.third ~ .services-card-dropdown {
            transform: translateX(calc((((100% / 3) * 2) + #{pxToRem(16)}) * -1));
        }
    }

    .services-card-dropdown {
        margin-bottom: pxToRem(24);
        transform: none;
        width: calc(300% + (#{pxToRem(24)} * 2));

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 0 pxToRem(55) 0;
        }
        
        &__list-item {
            margin-right: calc(25% / 2);
            width: calc(100% / 4);
            
            &:nth-child(3n + 3) {
                margin: 0;
            }
        }
        
        &__list-link {
            font-size: pxToRem(18);
        }
    }
}
