.home-scroll {
    display: none;
    padding: pxToRem(30) 0 pxToRem(15);

    &__inner {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    &__icon {
        align-items: flex-start;
        background-image: url($icon-path + 'scroll-down-oval.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        height: pxToRem(43);
        justify-content: center;
        width: pxToRem(28);
        
        &::before {
            background-image: url($icon-path + 'scroll-down-arrow.svg');
            content: '';
            display: block;
            height: pxToRem(14);
            margin-top: pxToRem(10);
            width: pxToRem(14);
        }
    }

    &__text {
        color: $swatch-green-darker;
        font-size: pxToRem(14);
        line-height: pxToRem(35);
        letter-spacing: 0;
        margin-bottom: 0;
        text-align: center;
    }
}

@media screen and (min-width: $md) {
    .home-scroll {
        display: block;
    }
}