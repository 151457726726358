.cta-image {
    background-color: $swatch-white;
    padding: pxToRem(40) pxToRem(15);
    
    &__image {
        background-repeat: no-repeat;
        background-size: cover;
        height: pxToRem(190);
        margin-bottom: pxToRem(30);
        width: 100%;
        
        &--center {
            background-position: center;
        }
        
        &--top {
            background-position: top;
        }
        
        &--right {
            background-position: right;
        }
        
        &--bottom {
            background-position: bottom;
        }
        
        &--left {
            background-position: left;
        }
    }
    
    &__content {
        background-color: $swatch-white;
        z-index: 0;
    }
    
    &__heading {
        font-size: pxToRem(25);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(32);
        margin: 0;
    }
    
    &__summary {
        margin: pxToRem(20) 0 0;
        font-size: pxToRem(15);
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        line-height: pxToRem(25);
    }
    
    &__button {
        background-color: $swatch-green-dark;
        color: $swatch-white;
        display: block;
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(18);
        margin-top: pxToRem(30);
        padding: pxToRem(20);
        text-align: center;
        text-decoration: none;
        
        &:focus,
        &:hover {
            color: $swatch-white;
            opacity: 0.8;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .cta-image {
        padding: pxToRem(60) 0;

        &__image {
            height: pxToRem(425);
            margin-bottom: 0;
        }
        
        &__content {
            margin-left: pxToRem(-84);
            padding: pxToRem(50) 0 pxToRem(50) pxToRem(60);
        }
        
        &__heading {
            font-size: pxToRem(30);
            font-weight: 800;
            line-height: pxToRem(41);
            letter-spacing: 0;
        }
        
        &__summary {
            font-size: pxToRem(18);
            margin-top: pxToRem(5);
        }
        
        &__button {
            display: inline-block;
            font-size: pxToRem(18);
            padding: pxToRem(10) pxToRem(15);
        }
    }
    
    .cta-image--reverse {
        .cta-image__content {
            margin-left: 0;
            margin-right: pxToRem(-84);
            padding: pxToRem(50) pxToRem(60) pxToRem(50) 0;
            width: calc(100% + #{pxToRem(84)});
            z-index: 0;
        }
    }
    
    .cta-image--full {
        margin-bottom:1.2rem;
        overflow: hidden;
        position: relative;
        
        .cta-image__image {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
        }
        
        .cta-image__content {
            padding-right: pxToRem(60);
        }
    }
}
