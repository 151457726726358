.key-info {
    margin: pxToRem(20) 0 pxToRem(25);

    &__content {
        border: pxToRem(1) solid $swatch-gradient-green-2;
        border-radius: pxToRem(5);
        padding: pxToRem(32) pxToRem(25) pxToRem(60);
    }
    
    ul {
        margin: 0;
        padding-left: pxToRem(18);
    }
    
    li {
        font-size: pxToRem(18);
        line-height: pxToRem(22);
        letter-spacing: 0;
        margin-bottom: pxToRem(20);
        padding-left: 0;
        
        &:last-child {
            margin: 0;
        }
    }
    
    a {
        color: $swatch-grey-light;
        
        &:focus,
        &:hover {
            color: $swatch-green-dark;
        }
    }
    
    h2 {
        font-size: pxToRem(18);
        font-weight: $font-weight-bold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        margin-bottom: pxToRem(20);
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .key-info {
        &__content {
            padding: pxToRem(25) pxToRem(45) pxToRem(45);
        }

        ul {
            padding-left: pxToRem(40);
        }

        li {
            font-size: pxToRem(18);
        }
    }
}
