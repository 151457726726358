.govt-tas {
    .footer {
        &__top {
            background-color: $swatch-govt-blue-1;
            padding: pxToRem(55) 0 pxToRem(40);
        }
        
        &__bottom {
            background-color: $swatch-govt-grey-1;
            padding: pxToRem(30) 0 pxToRem(40);
        }
        
        &__bottom-logos {
            margin-top: pxToRem(18);
        }
        
        a  {
            color: $swatch-white;
            
            &:focus,
            &:hover {
                color: $swatch-white;
            }
        }
    }
    
    .footer-panels {
        margin: 0;
    }
    
    .footer-panel {
        background-color: $swatch-govt-blue-2;
        padding: pxToRem(30) pxToRem(20) pxToRem(35) pxToRem(25);
    }
    
    .footer-wysiwyg {
        margin-top: pxToRem(35);
    }
    
    .footer-recognition {
        margin: pxToRem(35) 0 0;
        padding: pxToRem(25) pxToRem(20) pxToRem(20);
        
        p {
            font-weight: $font-weight-semibold;
            line-height: pxToRem(30);
            letter-spacing: 0;
            text-align: center;
        }
    }
}

@media screen and (min-width: $md) {
    .govt-tas {
        .footer {
            &__top {
                padding: pxToRem(70) 0 pxToRem(75);
            }
            
            &__bottom {
                padding: pxToRem(60) 0 pxToRem(70);
            }
            
            &__bottom-links li {
                margin-right: pxToRem(30);
            }
        }
    }
}

@media screen and (min-width: $xl) {
    .govt-tas .footer-recognition {
        padding: pxToRem(25) pxToRem(270) pxToRem(20);
    }
}
