.services-accordion {
    border-bottom: pxToRem(1) solid $swatch-grey-lightest;
    
    &.active {
        .services-accordion__heading,
        .services-accordion__heading:hover,
        .services-accordion__heading:focus {
            button {
                background-color: $swatch-white;
                color: $swatch-green-darkest;
                font-weight: $font-weight-black;

                &::after {
                    background-image: url($icon-path + 'chevron-down-green.svg');
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__heading {
        color: $swatch-black;

        button {
            -webkit-apearance: none;
            text-align: left;
            color: $swatch-black;
            align-items: center;
            background-color: $swatch-white;
            border: 0;
            display: flex;
            font-size: pxToRem(18);
            font-weight: $font-weight-semibold;
            justify-content: space-between;
            line-height: pxToRem(35);
            letter-spacing: 0;
            margin-bottom: 0;
            padding: pxToRem(20);
            position: relative;
            width: 100%;
            
            &:focus {
                outline: none;
            }
            
            &::before {
                bottom: pxToRem(-25);
                height: pxToRem(30);
                left: pxToRem(-1);
                position: absolute;
                width: calc(100% + #{pxToRem(2)});
            }
            
            &::after {
                background-image: url($icon-path + 'chevron-down.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: pxToRem(13) pxToRem(8);
                content: '';
                display: block;
                height: pxToRem(26);
                transition: transform 0.2s ease-in;
                width: pxToRem(26);
            }
        }
    }
}

@media screen and (min-width: $md) {
    .services-accordion {
        border-bottom: 0;
        margin-bottom: pxToRem(24);
        
        &.active {
            position: relative;
            z-index: 1;

            .services-accordion__heading,
            .services-accordion__heading:hover,
            .services-accordion__heading:focus {
                button {
                    background-color: $swatch-white;
                    border-bottom: 0;
                    border-color: $swatch-green-lighter;
                    border-top-left-radius: pxToRem(5);
                    border-top-right-radius: pxToRem(5);
                    color: $swatch-black;
                    font-weight: $font-weight-black;
                    
                    &::after {
                        background-image: url($icon-path + 'chevron-down-green.svg');
                        transform: rotate(180deg);
                    }

                    &::before {
                        background-color: $swatch-white;
                        border: pxToRem(1) solid $swatch-green-lighter;
                        border-bottom-color: $swatch-white;
                        border-top: 0;
                        content: '';;
                        transition: background-color 0.2s ease-in;
                        z-index: 2;
                    }
                }
            }
        }
        
        &__heading {
            button {
                border: pxToRem(1) solid $swatch-grey-border-card;
                font-weight: $font-weight-black;
                line-height: pxToRem(24);
                letter-spacing: 0;
                padding: pxToRem(40) pxToRem(45) pxToRem(35) pxToRem(40);
                transition: background-color 0.2s ease-in, color 0.2s ease-in;
                
                &::before {
                    z-index: 1;
                }
                
                &::after {
                    height: pxToRem(11);
                    width: pxToRem(17);
                }
                
                &:focus:not(:focus-visible),
                &:hover {
                    border-color: $swatch-green-darker;
                    color: $swatch-white;
                    background-color: $swatch-green-darker;
                    
                    &::after {
                        background-image: url($icon-path + 'chevron-down-white.svg');
                    }
                }
            }
        }
    }
}
