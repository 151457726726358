.header-nav-desktop {
  display: none;

  &__list {
    align-items: center;
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
  }
  
  &__item {
    margin-right: pxToRem(35);
    
    &--has-children {
      position: relative;

        &[data-menu-expanded="true"] {
            .header-nav-desktop__dropdown {
              display: block;
            }
            
            &::before,
            &::after {
              content: '';
            }
        }

      &::after,
      &::before {
        background: $swatch-white-lighter;
        bottom: pxToRem(-35);
        display: block;
        height: pxToRem(25);
        left: calc(50% - 10px);
        position: absolute;
        transform: translateX(-50%) rotate(45deg);
        width: pxToRem(25);
        z-index: 0;
      }
      
      &::after {
        box-shadow: 0 0 pxToRem(4) 0 #00000040;
      }
      
      &::before {
        z-index: 2;
      }

      & > .header-nav-desktop__link {
        font-weight: $font-weight-bold;
      }

      & > .header-nav-desktop__link::after {
        background-image: url('~src/modules/SVGs/chevron-down.svg');
        content: '';
        height: pxToRem(6);
        margin-left: pxToRem(5);
        width: pxToRem(12);
      }

      &.active::after,
      &.active::before {
        content: '';
      }

      &.active .header-nav-desktop__dropdown {
        display: block;
      }
    }
    
    &--account >  .header-nav-desktop__link::before {
      background-image: url('~src/modules/SVGs/account.svg');
      content: '';
      height: pxToRem(24);
      margin-right: pxToRem(5);
      width: pxToRem(24);
    }
  }
  
  &__link {
    align-items: center;
    color: $swatch-grey-darker;
    display: flex;
    font-size: pxToRem(16);
    font-weight: $font-weight-semibold;
    line-height: pxToRem(30);
    letter-spacing: 0;
    text-decoration: none;
    
    &:focus,
    &:hover {
      color: $swatch-green-darker;
      cursor: pointer;
      text-decoration: none;
    }
    
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
    }
  }
  
  &__dropdown {
    display: none;
    left: pxToRem(-100);
    position: absolute;
    
    &:focus,
    &:hover {
      display: block;
    }
  }
  
  &__list--child {
    align-items: flex-start;
    background-color: $swatch-white-lighter;
    box-shadow: 0 0 pxToRem(4) 0 #00000040;
    display: flex;
    flex-wrap: wrap;
    margin-top: pxToRem(20);
    padding: pxToRem(35) pxToRem(50);
    width: pxToRem(700);
    z-index: 1;

    .header-nav-desktop__item {
      display: inline-block;
      margin: 0;
      margin-right: pxToRem(60);
      width: calc(50% - #{pxToRem(30)});
      
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    
    .header-nav-desktop__link {
      align-items: flex-start;
      font-size: pxToRem(16);
      font-weight: $font-weight-semibold;
      line-height: pxToRem(45);
      letter-spacing: 0;
      justify-content: space-between;
      text-align: left;
      width: 100%;
    
      &:focus::after,
      &:hover::after {
        background-image: url('~src/modules/SVGs/chevron-right-green.svg');
      }

      &::after {
        background-image: url('~src/modules/SVGs/chevron-right.svg');
        content: '';
        height: pxToRem(17);
        margin: pxToRem(14) 0 0 pxToRem(5);
        width: pxToRem(11);
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .header-nav-desktop {
    display: flex;
    
    &__item--has-children::before {
      z-index: 5;
    }
    
    &__dropdown {
      z-index: 4;
    }
    
    &__list--child {
      z-index: 2;
    }
  }
}
