.services-card {
    background-color: $swatch-white;
    border: pxToRem(1) solid $swatch-grey-lightest;
    cursor: pointer;
    margin-bottom: pxToRem(10);
    padding: pxToRem(16) pxToRem(60) pxToRem(25) pxToRem(30);
    position: relative;
    
    &:focus {
        outline: none;
    }
    
    &.active {
        border-color: $swatch-green-lighter;
        border-width: 2px;
        border-bottom: 0;
        
        .services-card__toggle-accordion {
            transform: rotate(180deg);
        }
    }

    &__toggle-accordion {
        background-image: url($icon-path + 'chevron-down.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        bottom: pxToRem(30);
        content: '';
        display: block;
        height: pxToRem(10);
        position: absolute;
        right: pxToRem(22);
        transition: transform 0.2s ease-in;
        width: pxToRem(17);
        background-color: transparent;
        border: unset;
    
        [data-card-title] {
            display: none;
        }
    }
        
    &__icon {
        background-color: $swatch-green-light;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        border-radius: 50%;
        display: inline-block;
        height: pxToRem(40);
        width: pxToRem(40);
        
        @each $icon in $services-icons {
            &--#{$icon} {
                background-image: url($icon-path + $icon + '.svg');
            }
        }
    }

    &__heading {
        color: $swatch-grey-darkest;
        display: inline-block;
        font-size: pxToRem(18);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(24);
        margin: pxToRem(10) 0 0;
        text-decoration: none;

        &:focus,
        &:hover {
            color: $swatch-green-darker;
        }
    }
    
    &__summary {
        color: $swatch-grey-darkest;
        font-size: pxToRem(14);
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        line-height: pxToRem(21);
        margin: pxToRem(5) 0 0;
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $lg) {
    .services-card {
        height: calc(100% - #{pxToRem(24)});
        margin-bottom: pxToRem(24);
        padding: pxToRem(30) pxToRem(45) pxToRem(90);

        &.active {            
            .services-card__toggle-accordion {
                color: $swatch-green-darker;
                transform: none;

                &::before {
                    background-color: $swatch-green-lighter;
                }
            }
            
            .services-card__connector {
                display: block;
            }
        }
        
        &:focus:not(.active),
        &:hover:not(.active) {
            background-color: $swatch-green-darker;
            background-image: url($icon-path + 'services-card-bg.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transition: background-color 0.2s;
            
            .services-card__heading,
            .services-card__summary {
                color: $swatch-white;
            }
            
            &::after {
                color: $swatch-white;
            }

            .services-card__toggle-accordion {
                color: $swatch-white;
            }
        }
    
        &__toggle-accordion {
            display: block;
            background-image: none;
            background-color: transparent;
            border: unset;
            bottom: pxToRem(35);
            color: $swatch-grey-light;
            font-size: pxToRem(12);
            font-weight: $font-weight-bold;
            line-height: pxToRem(21);
            letter-spacing: 0.11em;
            height: auto;
            margin-top: pxToRem(30);
            right: pxToRem(50);
            text-align: right;
            transition: color 0.2s ease-in;
            text-transform: uppercase;
            width: auto;
            position: absolute;
    
            [data-card-title] {
                display: block;
            }

            &::before {
                background-color: $swatch-white;
                bottom: pxToRem(11);
                content: '';
                display: block;
                height: pxToRem(1);
                position: absolute;
                left: pxToRem(-30);
                width: pxToRem(25);
            }
        }
        
        &__icon {
            height: pxToRem(55);
            width: pxToRem(55);
        }

        &__heading {
            font-size: pxToRem(25);
            line-height: pxToRem(32);
            transition: color 0.2s ease-in;
        }
        
        &__summary {
            font-size: pxToRem(18);
            margin-top: pxToRem(20);
            transition: color 0.2s ease-in;
        }
        
        &__connector {
            background-color: $swatch-white;
            border: pxToRem(2) solid $swatch-green-lighter;
            border-bottom-color: $swatch-white;
            border-top: 0;
            bottom: 0;
            display: none;
            height: pxToRem(27);
            left: 0;
            position: absolute;
            transform: translate(#{pxToRem(-2)}, #{pxToRem(26)});
            width: calc(100% + #{pxToRem(4)});
            z-index: 2;
        }
    }
}
