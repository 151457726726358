.sub-category-listing {
    padding: pxToRem(40) 0 pxToRem(70);
    position: relative;

    &::after {
        background-color: $swatch-white;
        bottom: pxToRem(70);
        content: '';
        height: pxToRem(5);
        left: 0;
        position: absolute;
        width: 100%;
    }

    @include focus-state($swatch-black);
}

.sub-category-listing-item {
    align-items: center;
    border: pxToRem(1) solid $swatch-grey-border-card;
    display: flex;
    font-size: pxToRem(18);
    font-weight: $font-weight-semibold;
    height: 100%;
    justify-content: space-between;
    line-height: pxToRem(35);
    letter-spacing: 0;
    margin-bottom: 0;
    padding: pxToRem(10);
    position: relative;
    text-decoration: none;
    
    &:focus {
        outline: none;
    }
}

@media screen and (min-width: $md) {
    .sub-category-listing {
        padding: pxToRem(120) 0 pxToRem(80);
    }

    .sub-category-listing-item {
        font-weight: $font-weight-black;
        line-height: pxToRem(24);
        letter-spacing: 0;
        padding: pxToRem(20);
        transition: background-color 0.2s ease-in, color 0.2s ease-in;

        &:focus,
        &:hover {
            border-color: $swatch-green-darker;
            color: $swatch-white;
            background-color: $swatch-green-darker;
        }
    }
}
