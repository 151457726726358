@import './header-nav.scss';
@import './header-top.scss';
@import './header-flyout.scss';
@import './header-search.scss';
@import './header-nav-desktop.scss';
@import './govt-tas.scss';

.header {
  overflow-x: hidden;
  position: relative;
  
  &.header--search > .container-fluid .row .col-12:first-child {
    background-color: $swatch-white;
    z-index: 5;
  }
}

@media screen and (min-width: $md) {
  .header {
    overflow-x: visible;
  }
}