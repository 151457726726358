.header-nav {
  padding: pxToRem(25);
  display: flex;
  justify-content: space-between;

    @include focus-state($swatch-black);
}

.header-nav-logos {
  display: flex;
  justify-content: space-between;
  
  &__tas-govt {
    height: pxToRem(32);
    width: pxToRem(35);
  }
  
  &__serv-tas {
    height: pxToRem(31.5);
    width: pxToRem(77.5);
  }
  
  &__divider {
    background-color: $swatch-grey-darkest;
    display: inline-block;
    height: 100%;
    margin: 0 pxToRem(5);
    width: 2px;
  }
}

.header-nav-buttons {
  align-items: center;
  display: flex;
}

.header-nav-button {
  background-color: $swatch-white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  height: pxToRem(20);
  width: pxToRem(20);
  
  &--toggle-nav {
    background-image: url('~src/modules/SVGs/hamburger.svg');
    margin-right: pxToRem(20);
  }
  
  &--toggle-search {
    background-image: url('~src/modules/SVGs/search-glass.svg');
    
    &.active {
      background-image: url('~src/modules/SVGs/close-green.svg');
    }
  }
}

/*
* Tablet layout
*/

@media screen and (min-width: $lg) {
  .header-nav-logos {
    &__tas-govt {
      height: pxToRem(49);
      width: pxToRem(52);
    }
    
    &__serv-tas {
      height: pxToRem(47);
      width: pxToRem(116);
    }
  }
  
  .header-nav-buttons {
    display: none;
  }
}
