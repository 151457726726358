.header-search {
  display: none;
  
  &--flyout {
    display: block;

    .header-search__input {
      width: 100%;
    }
    
    i.icon-clear {
      top: pxToRem(24);
    }
  }

  &__form {
    align-items: stretch;
    display: flex;
    height: 40px;
    position: relative;
  }

  &__input {
    background-image: url($icon-path + 'search-glass.svg');
    background-repeat: no-repeat;
    background-size: pxToRem(17) pxToRem(17);
    background-position: pxToRem(10);
    border: pxToRem(1) solid $swatch-grey-lightest;
    font-size: pxToRem(14);
    font-weight: $font-weight-bold;
    height: 40px;
    line-height: pxToRem(18);
    letter-spacing: 0;
    margin-right: pxToRem(-5);
    padding: pxToRem(10) pxToRem(10) pxToRem(10) pxToRem(33);
    width: pxToRem(325);

    //&::placeholder {
        //letter-spacing: 0;    
        //word-spacing: initial;    
        //line-height: 1.125rem;    
    //}
    
    &:focus {
      background-image: url($icon-path + 'search-icon-green.svg');
      border-color: $swatch-green-darkest;
      color: $swatch-green-darkest;
      outline: 0;
    }
  }
  
  .icon-clear {
    background-image: url($icon-path + 'close-icon-green.svg');
    background-size: contain;
    cursor: pointer;
    height: pxToRem(14);
    position: absolute;
    right: pxToRem(75);
    top: pxToRem(24);
    transform: translate(pxToRem(-10), pxToRem(-11));
    width: pxToRem(14);
    
    &.disabled {
        display: none;
    }
}
  
  &__submit {
    background-color: $swatch-green-darker;
    border: none;
    color: $swatch-white;
    font-size: pxToRem(15);
    font-weight: $font-weight-normal;
    max-height: pxToRem(40);
    line-height: pxToRem(30);
    letter-spacing: 0;
    padding: pxToRem(5) pxToRem(15);
    
    &:focus,
    &:hover {
      background-color: lighten($swatch-green-darker, 10%);
    }
  }
}

@media screen and (min-width: $md) {
  .header-search {
    display: block;
  }
}
