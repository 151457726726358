#google-map {
    height: 700px;
    width: 100%;
}


.infowindow-service-centers {
    border-color: $swatch-grey-lightest;
    
    p, a {
        letter-spacing: 0;
        line-height: pxToRem(18);
    }

    p {
        color: $swatch-grey-darkest;
    }
    
    a {
        color: $swatch-green-darker;
        font-weight: $font-weight-semibold;
    }
    
    &__item {
        display: flex;
        padding: pxToRem(15) pxToRem(15) pxToRem(15) pxToRem(30);
        
        &::before {
            background-image: url($icon-path + 'google-pin-green.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: pxToRem(25);
            margin: 0  pxToRem(20) 0 pxToRem(-15);
            width: pxToRem(18);            
        }
    }
    
    &__content {
        width: calc(100% - #{pxToRem(18)});
    }
    
    &__town {
        font-size: pxToRem(18);
        font-weight: $font-weight-bold;
        margin-bottom: pxToRem(5);
    }

    &__town,
    &__address {
        line-height: pxToRem(20);
    }
    
    &__summary {
        margin-bottom: 0;
        
        span {
            line-height: pxToRem(20);
        }
    }

    &__link[href] {
        font-weight: $font-weight-bold;
    }

    button:focus, 
    a:focus, 
    input:focus,
    textarea:focus {
        outline: 2px solid $swatch-black !important; 
        outline-offset: 0;
    }
}
