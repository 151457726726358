.banner--landing {
    .container-fluid {
        position: relative;
        z-index: 1;
    }

    .banner__breadcrumbs {
        background-color: $swatch-green-darker;
        display: flex;
        flex-wrap: wrap;
        height: pxToRem(44);
        list-style-type: none;
        margin: pxToRem(-22) 0 0;
        overflow-y: clip;
        padding: pxToRem(5) 0 pxToRem(15);
        position: relative;
        width: calc(100% + #{pxToRem(15)});
        z-index: 0;
        
        &::before {
            background-color: $swatch-green-darker;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            left: -75vw;
            top: 0;
            width: 100vw;
            z-index: 0;
        }
        
        &::after {
            background: $banner-breadcrumbs-gradient;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            width: pxToRem(100);
            z-index: 1;
        }
    }
    
    .banner__breadcrumb-item {
        align-items: center;
        display: none;
        z-index: 1;

        &::before {
            border-top: 1px solid $swatch-white;
            border-right: 1px solid $swatch-white;
            color: $swatch-white;
            content: none;
            height: pxToRem(5);
            margin: 0 pxToRem(10) 0 pxToRem(7);
            transform: rotate(45deg);
            width: pxToRem(5);
        }

        &:first-child::before {
            content: none;
        }
        
        &:last-child {
            display: flex;
        }
        
        &:last-child > .banner__breadcrumb-link {
            font-weight: $font-weight-normal;
        }
    }
    
    .banner__breadcrumb-link {
        color: $swatch-white;
        font-size: pxToRem(14);
        font-weight: $font-weight-bold;
        line-height: pxToRem(30);
        letter-spacing: 0;
    }

    .banner__content {
        padding: pxToRem(22) pxToRem(15) pxToRem(38);
    }

    .banner__heading {
        background-color: $swatch-green-dark;
        font-size: pxToRem(24);
        font-weight: $font-weight-black;
        line-height: pxToRem(35);
        letter-spacing: -0.02em;
        padding-top: pxToRem(22);
        position: relative;
        z-index: 1;
    }
    
    .banner__subheading {
        font-size: pxToRem(14);
        font-weight: $font-weight-normal;
        line-height: pxToRem(18);
        letter-spacing: 0;
        margin-top: pxToRem(10);
    }

    .banner__feedback,
    .banner__print {
        display: none;
    }
}

@media screen and (min-width: $sm) {
    .banner--landing {
        &.banner::before,
        &.banner::after {
            width: 100%;
        }
        
        .banner__content {
            margin: pxToRem(50) 0 pxToRem(70);
            padding-bottom: pxToRem(65);
        }
        
        .banner__breadcrumbs {
            margin-bottom: pxToRem(30);
        }
        
        .banner__heading {
            font-size: pxToRem(35);
            margin-bottom: pxToRem(15);
        }
        
        .banner__subheading {
            font-size: pxToRem(18);
            font-weight: $font-weight-bold;
            line-height: pxToRem(18);
        }
        
        .banner__print {
            display: block;
            position: absolute;
            right: 0;
            top: pxToRem(50);
            z-index: 1;
        }
        
        .banner__print-link {
            align-items: center;
            background-color: $swatch-green-darker;
            color: $swatch-white;
            display: flex;
            padding: pxToRem(20) pxToRem(60) pxToRem(20) pxToRem(25);
            text-decoration: none;
            transition: 0.5s opacity ease-in;
            
            &:focus,
            &:hover {
                color: $swatch-white;
                opacity: 0.8;
            }
            
            &::before {
                background-image: url($icon-path + 'print.svg');
                background-position: center;
                background-size: cover;
                content: '';
                display: inline-block;
                height: pxToRem(25);
                margin-right: pxToRem(10);
                width: pxToRem(25);
            }
        }
    }
}


@media screen and (min-width: $lg) {
    .banner--landing .banner__breadcrumb-item {
        display: flex;

        &::before {
            content: '';
        }
    }
}