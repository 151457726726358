.services-accordion-dropdown {
    height: 0;
    overflow: hidden;
    transition: height 0.2s ease-in;
    margin-top: -8px;

    &[aria-hidden="true"] {
        .services-accordion-dropdown__content {
            display: none;
        }
    }

    &__heading {
        font-size: pxToRem(18);
        font-weight: $font-weight-bold;
        margin: 0;
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0 0 pxToRem(35) pxToRem(75);

        & ~ .services-accordion-dropdown__heading {
            margin-top: 20px;
        }
    }

    &__list-item {
        margin-bottom: pxToRem(15);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__list-link {
        align-items: center;
        color: $swatch-black;
        display: flex;
        font-size: pxToRem(18);
        font-weight: $font-weight-normal;
        justify-content: space-between;
        line-height: pxToRem(30);
        letter-spacing: 0;
        padding-right: pxToRem(15);
        position: relative;
        text-decoration: none;

        &::after {
            background-image: url($icon-path + 'chevron-right.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: pxToRem(12);
            position: absolute;
            right: 0;
            width: pxToRem(8);
        }

        &:focus,
        &:hover {
            color: $swatch-green-darker;
            text-decoration: underline;

            &::after {
                background-image: url($icon-path + 'chevron-right-green.svg');
            }
        }
    }
}

@media screen and (min-width: $md) {
    .services-accordion-dropdown {
        width: calc(200% + #{pxToRem(24)});

        &.second {
            transform: translateX(calc(-50% - #{pxToRem(12)}));
        }

        &__heading {
            font-size: pxToRem(24);
            margin-left: 0;
        }

        &__content {
            border: pxToRem(1) solid $swatch-green-lighter;
            margin-top: pxToRem(24);
            display: block;
        }

        &__inner {
            padding: pxToRem(55) pxToRem(25) pxToRem(55) pxToRem(55);
            display: flex;
            flex-direction: column;
            gap: 60px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 0;
            gap: 10px;
        }

        &__list-item {
            margin-right: 3%;
            width: 30%;

            &:nth-child(3n + 3) {
                margin: 0;
            }
        }

        &__list-link {
            padding: 0 pxToRem(10) 0 0;

            &::after {
                content: '';
            }

            &:focus,
            &:hover {
                color: $swatch-black;
                font-weight: $font-weight-normal;
                text-decoration: none;
            }
        }
    }
}
