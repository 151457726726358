.cta-panel-narrow {
    padding: pxToRem(50) 0 pxToRem(35);

    &__inner {
        background-color: $swatch-green-darker;
    }
    
    &__content {
        padding: pxToRem(30) pxToRem(40) pxToRem(30) pxToRem(30);

        * {
            color: $swatch-white;
            font-weight: $font-weight-normal;
            line-height: pxToRem(24);
            letter-spacing: 0;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            font-weight: $font-weight-bold;

            &:focus,
            &:hover {
                color: $swatch-white;
            }
        }
    }
    
    &__img {
        display: none;
        
        &.center {
            background-position: center;
        }
        
        &.top {
            background-position: top;
        }
        
        &.right {
            background-position: right;
        }
        
        &.bottom {
            background-position: bottom;
        }
        
        &.left {
            background-position: left;
        }
    }

    @include focus-state($swatch-white);
}

@media screen and (min-width: $md) {
    .cta-panel-narrow {
        padding: pxToRem(60) 0;

        &--reverse .cta-panel-narrow__inner {
            flex-direction: row-reverse;
        }
        
        &__inner {
            align-items: stretch;
            display: flex;
        }

        &__content {
            padding: pxToRem(50) pxToRem(60);
            width: 60%;
        }

        &__img {
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 40%;
        }
    }
}
