.page-utilities {
    padding: pxToRem(30) pxToRem(15);
    
    &__share,
    &__print {
        align-items: center;
        color: $swatch-grey-dark;
        cursor: pointer;
        display: flex;
        font-size: pxToRem(16);
        font-weight: 400;
        line-height: pxToRem(18);
        letter-spacing: 0;
        margin-bottom: 0;
        text-decoration: none;
        
        &::before {
            background-image: url($icon-path + 'share.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: block;
            height: pxToRem(18);
            margin-right: pxToRem(10);
            width: pxToRem(18);
        }
    }
    
    &__share {
        margin-right: pxToRem(20);
    }
    
    &__print::before {
        background-image: url($icon-path + 'print-black.svg');
        height: pxToRem(16);
        width: pxToRem(16);
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .page-utilities {
        padding: pxToRem(30) 0;
    }
}
