.search-cards {
    position: relative;
    padding: 0 0 pxToRem(45);
    
    &::after {
        background-color: $swatch-grey-border-card;
        bottom: 0;
        content: '';
        height: pxToRem(1);
        left: pxToRem(12);
        position: absolute;
        width: calc(100% - #{pxToRem(24)});
    }
}

.search-card {
    background: $swatch-white;
    color: $swatch-black;
    cursor: pointer;
    display: block;
    margin-bottom: pxToRem(15);
    padding: pxToRem(20) pxToRem(15) pxToRem(30);
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: transform 0.2s ease-in;
    
    &:focus,
    &:hover {
        color: $swatch-black;
        transform: scale(1.01);
    }

    &:last-child {
        margin-bottom: 0;
    }
    
    &__title,
    &__summary {
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(20);
        margin-bottom: pxToRem(15);
    }
    
    &__title {
        color: $swatch-green-darker;      
    }
    
    &__summary {
        font-weight: $font-weight-normal;
        margin-bottom: 0;
        
        strong {
            color: $swatch-green-darker;
        }
    }
}

@media screen and (min-width: $md) {
    .search-cards {
        padding-bottom: pxToRem(60);

        &::after {
            left: calc((100vw / 12) + #{pxToRem(12)});
            width: calc(((100vw / 12) * 10) - #{pxToRem(24)});
        }
    }
    
    .search-card {
        border: pxToRem(1) solid $swatch-grey-border-card;
        margin-bottom: pxToRem(10);
        padding: pxToRem(40) pxToRem(60) pxToRem(40) pxToRem(45);
        
        &__title,
        &__summary {
            font-size: pxToRem(18);
            line-height: pxToRem(30);
        }
    }
}
