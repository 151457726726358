.on-this-page {
    padding: pxToRem(16) 0 pxToRem(24);
    
    h3 {
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        margin-bottom: pxToRem(20);
    }
    
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    
    li {
        margin-bottom: pxToRem(10);
        
        :last-child {
            margin-bottom: 0;
        }
    }
    
    li a {
        color: $swatch-grey-light;
        font-size: pxToRem(16);
        font-weight: $font-weight-normal;
        line-height: pxToRem(18);
        letter-spacing: 0;
        
        &:focus,
        &:hover {
            color: $swatch-green-dark;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .on-this-page {
        padding: pxToRem(20) 0 pxToRem(30);
        
        h3,
        li a {
            font-size: pxToRem(18);
        }
    }
}
