.footer {
    * {
        color: $swatch-white;
        font-size: pxToRem(18);
        line-height: pxToRem(30);
        letter-spacing: 0;
        text-align: left;
        text-decoration-color: $swatch-white;
    }
    
    &__heading {
        color: $swatch-white;
        font-size: pxToRem(24);
        font-style: normal;
        font-weight: 800;
        line-height: pxToRem(35);
        letter-spacing: -0.02em;
        margin: 0;
    }
    
    &__top {
        background-color: $swatch-grey-dark;
        padding: pxToRem(40) 0 pxToRem(55);
    }
    
    &__bottom {
        background-color: $swatch-grey-darkest;
        padding: pxToRem(50) 0 pxToRem(60);        
    }

    @include focus-state($swatch-white);
}

.footer-panels {
    display: flex;
    flex-direction: column;
    margin-top: pxToRem(35);
}

.footer-panel {
    align-items: center;
    background: $swatch-grey-light;
    box-shadow: 0px pxToRem(4) pxToRem(40) 0px #00000026;
    display: flex;
    margin-top: pxToRem(20);
    padding: pxToRem(25);
    width: 100%;
    
    &:first-child {
        margin-top: 0;
    }
    
    img {
        margin-right: pxToRem(25);
    }
    
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        
        a {
            display: block;
        }
        
        a:hover,
        a:focus {
            color: $swatch-white;
        }
    }
    
    p {
        margin-bottom: 0;
    }
}

.footer-wysiwyg {
    margin-top: pxToRem(50);
    
    * {
        margin-bottom: 0;
    }
    
    a:focus,
    a:hover {
        color: $swatch-white;
        text-decoration: underline;
    }
}

.footer-social {
    margin-top: pxToRem(30);
    
    .footer-social-icons {
        display: flex;
    }
    
    .footer-social-icon {
        align-items: center;
        border: pxToRem(1) solid $swatch-white;
        border-radius: 50%;
        display: flex;
        height: 30px;
        justify-content: center;
        margin-right: pxToRem(15);
        width: 30px;
        
        &:last-child {
            margin-right: 0;
        }
        
        img {
            margin-top: pxToRem(-3);
        }
        
        &--facebook img {
            height: 18px;
            margin-right: 1px;
            width: 9px;
        }
        
        &--twitter img {
            height: 14px;
            width: 18px;            
        }
    }
}

.footer-recognition {
    border: 1px solid $swatch-white;
    margin-top: pxToRem(45);
    padding: pxToRem(25);
    
    p {
        font-size: pxToRem(18);
        font-weight: 700;
        letter-spacing: 0;
        margin-bottom: 0;
    }
}

.footer__bottom-links {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;

    a {
        font-size: pxToRem(16);
        font-weight: $font-weight-normal;
        text-decoration: none;
        
        &:focus,
        &:hover {
            color: $swatch-white;
            text-decoration: underline;
        }
    }
}

.footer__bottom-logos {
    margin-top: pxToRem(30);
    
    .footer__bottom-logo {
        display: inline-block;
        margin-right: pxToRem(15);
        position: relative;
        text-decoration: none;        
        
        &::after {
            background-color: $swatch-white;
            content: '';
            position: absolute;
            height: 100%;
            right: pxToRem(-11);
            top: 0;
            width: pxToRem(2);
        }
        
        &:last-child {
            margin-right: 0;
            
            &::after {
                content: none;
            }
        }
        
        &--govt img {
            height: pxToRem(42);
            width: pxToRem(45);
        }

        &--services img {
            height: pxToRem(41);
            width: pxToRem(100);
        }
    }
}

@media screen and (min-width: $md) {
    .footer {
        &__top {
            padding-left: 0;
            padding-right: 0;
        }

        &__heading {
            font-size: pxToRem(35);
        }
    }
    
    .footer-panels {
        flex-direction: row;
        justify-content: space-between;
        margin-top: pxToRem(55);
    }
    
    .footer-panel {
        margin-left: pxToRem(20);
        margin-top: 0;
        
        &__content a {
            display: inline;
        }
        
        &:first-child {
            margin-left: 0;
        }
    }
    
    .footer__bottom {
        padding: pxToRem(60) 0 pxToRem(65);
    }
    
    .footer__bottom-links li {
        display: inline-block;
        margin-right: pxToRem(20);
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    .footer__bottom-links a {
        font-size: pxToRem(18);
    }
    
    .footer-recognition {
        padding: pxToRem(25) pxToRem(90);
        
        p {
            text-align: center;
        }
    }
}
