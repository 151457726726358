/*
 * Mobile layout
 */

body {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100vw;
    z-index: 1;

    .stretch {
        margin-left: calc(((100vw - 100%) / 2) * -1);
        width: 100vw;
        
        & > .container-fluid {
            padding: 0 pxToRem(12);
        }
    }
}

.search-results {
    background-color: $swatch-white-lighter;
}

#hide-cov-link-mobile {
    display: none;
}

#header-title {
    font-size: pxToRem(13);
}

// from matrix
#select-lang {
    cursor: pointer;
    background: $swatch-grey-dark;
    color: $swatch-white;
    border: none;
    font-size: pxToRem(12);
}

.translate__logo {
    display: none;
}
.translate {
    margin-left: pxToRem(0) !important;
}

/*
 * Tablet layout
 */

@media screen and (min-width: $md){
    body {
        z-index: 2;
    }
    #hide-cov-link-mobile {
        display: flex;
    }
    #header-title {
        font-size: pxToRem(16);
    }
    .translate {
        margin-left: pxToRem(30) !important;
    }
    #select-lang {
        font-size: pxToRem(16);
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: $lg){
    body {
        z-index: 3;
    }
    #hide-cov-link-mobile {
        display: flex;
    }
    #header-title {
        font-size: pxToRem(16);
    }
    .translate {
        margin-left: pxToRem(30) !important;
    }
    #select-lang {
        font-size: pxToRem(16);
    }
}
