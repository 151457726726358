// Base font
$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #4a4a4a;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Colours
$swatch-access-blue:        #3366CC;
$swatch-black:              #000000;
$swatch-gradient-green-1:   #209b52;
$swatch-gradient-green-2:   #7aaf51;
$swatch-green-kms-1:        $swatch-gradient-green-2;
$swatch-grey-border-card:   #c4c4c4;
$swatch-green-dark:         #098851;
$swatch-green-darker:       #267151;
$swatch-green-darkest:      #277151;
$swatch-green-light:        #92BE55;
$swatch-green-lighter:      #75BD43;
$swatch-grey-border-card:   #c4c4c4;
$swatch-grey-darkest:       #333333;
$swatch-grey-darker:        #343534;
$swatch-grey-dark:          #404041;
$swatch-grey-lightest:      #E7E7E8;
$swatch-grey-lighter:       #E5E5E5;
$swatch-grey-light:         #4F4F4F;
$swatch-red-alert:          #CD0C0C;
$swatch-red-warn:           #a30000;
$swatch-red-warn-bg:        #ffebeb;
$swatch-white:              #ffffff;
$swatch-white-lighter:      #F5F5F5;
$swatch-white-off:          #FBFBFB;

// Colours govt
$swatch-govt-blue-1:        #1A2834;
$swatch-govt-blue-2:        #253746;
$swatch-govt-blue-3:        #326295;
$swatch-govt-grey-1:        #0D1720;

// Custom gradients
$banner-gradient: linear-gradient(90deg, #{$swatch-gradient-green-1} 0%, #{$swatch-gradient-green-2} 100%);
$banner-breadcrumbs-gradient: linear-gradient(270deg, $swatch-green-darker 44.27%, rgba(38, 113, 81, 0) 100%);

// Font weights
$font-weight-semibold:      600;
$font-weight-black:         800;

// Responsive breakpoints to match with Bootstrap 5: https://getbootstrap.com/docs/5.0/layout/breakpoints/#available-breakpoints
$xs:    0;
$sm:    576px;
$md:    768px;
$lg:    992px;
$xl:    1200px;
$xxl:   1400px;

$icon-path: '~src/modules/SVGs/';
$services-icons:
    'births',
    'boats',
    'education',
    'emergencies',
    'government-support',
    'health',
    'housing-property',
    'justice',
    'identity',
    'recreation',
    'transport',
    'work'
;
