// Table formatting
table:not(#callOut) {
    margin: 0 0 pxToRem(20) !important;
    width: 100%;
}

table:not(#callOut) .contentstyles h2 {
    /* These are the main headings */
    color: $swatch-white;
    text-align: left;
    font-weight: normal;
    background-color: $swatch-green-dark;
    padding: pxToRem(4);
    border-radius: pxToRem(5) pxToRem(5) pxToRem(5) pxToRem(5);
    padding-left: pxToRem(10);
}

table:not(#callOut) th,
table:not(#callOut) td {
    border: pxToRem(1) solid $swatch-green-dark;
    padding: pxToRem(8);
}

table:not(#callOut) th:not(:last-child) {
    border-right-color: $swatch-white;
}

table:not(#callOut) tr:nth-child(even) {
    background-color: $swatch-white;
}

table:not(#callOut) td[rowspan] {
    background-color: #ddd;
}

table:not(#callOut) th {
    background-color: $swatch-green-dark;
    color: $swatch-white;
    padding-top: pxToRem(12);
    padding-bottom: pxToRem(12);
    text-align: left;
    word-break: keep-all;
}

#fpo_payment_options~table tr:not(:last-child) th {
    border-bottom-color: $swatch-white;
    border-right-color: $swatch-green-kms-1;
}

table:not(#callOut) .contentstyles h2 {
    /* These are the main headings */
    color: $swatch-white;
    text-align: left;
    font-weight: normal;
    background-color: $swatch-green-dark;
    padding: pxToRem(4);
    border-radius: pxToRem(5) pxToRem(5) pxToRem(5) pxToRem(5);
    padding-left: pxToRem(10);
}

table:not(#callOut) {
    border-collapse: collapse;
    word-break: break-word;
    margin-right: pxToRem(8);
    margin-top: pxToRem(10);
    width: 100%;
}

table:not(#callOut) th,
table:not(#callOut) td {
    border: pxToRem(1) solid $swatch-green-dark;
    font-size: pxToRem(18);
    padding: pxToRem(8);
}

table:not(#callOut) th:not(:last-child) {
    border-right-color: $swatch-white;
}

table:not(#callOut) tr:nth-child(even) {
    background-color: $swatch-white;
}

table:not(#callOut) td[rowspan] {
    background-color: #ddd;
}

table:not(#callOut) th {
    padding-top: pxToRem(12);
    padding-bottom: pxToRem(12);
    text-align: left;
    background-color: $swatch-green-dark;
    color: $swatch-white;
}

#fpo_payment_options~table tr:not(:last-child) th {
    border-bottom-color: $swatch-white;
    border-right-color: $swatch-green-kms-1;
}
