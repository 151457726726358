.banner-tabs {
    .nav-tabs {
        border-bottom-color: $swatch-green-dark;
        flex-wrap: nowrap;
        margin-left: pxToRem(-12);
        width: 100vw;
    }
    
    .nav-item {
        flex-grow: 1;
    }
    
    .nav-link {
        background-color: $swatch-green-darker;
        border-radius: 0;
        color: $swatch-white;
        font-weight: $font-weight-bold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        padding: pxToRem(13) pxToRem(35);
        text-align: center;
        text-decoration: underline;
        width: 100%;
        
        &:hover,
        &:focus {
            border-color: $swatch-green-dark;
            font-weight: bold;
            
            &:not(.active) {
                opacity: 0.8;
            }
        }
        
        &.active {
            background-color: $swatch-green-dark;
            border-color: $swatch-green-dark;
            color: $swatch-white;
            text-decoration: none;
        }
    }
    
    .tab-content {
        background-color: $swatch-green-dark;
        color: $swatch-white;
        padding: pxToRem(20) 0 pxToRem(5);
        position: relative;
        
        &::before {
            background-color: $swatch-green-dark;
            content: '';
            display: block;
            height: calc(100% + #{pxToRem(1)});
            left: pxToRem(-12);
            position: absolute;
            top: pxToRem(-1);
            width: 100vw;
            z-index: 0;
        }
    }
    
    .tab-pane {
        ul {
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 1;
        }
        
        &.active {
            ul {
                display: flex;
            }
        }
    }
    
    .tab-pane__item {
        margin: 0 pxToRem(10) pxToRem(10) 0;
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    .tab-pane__link {
        border: pxToRem(1) solid $swatch-white;
        border-radius: pxToRem(32);
        color: $swatch-white;
        display: block;
        font-size: pxToRem(16);
        font-weight: $font-weight-semibold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        padding: pxToRem(8) pxToRem(15);
        text-align: center;
        text-decoration: none;
        transition: all 0.2s ease-in;

        &:is(:hover, :focus) {
            background-color: $swatch-white;
            color: $swatch-green-dark;
            font-weight: bold;
        }

        &:is(:focus-visible) {
            outline-offset: 3px;
            outline: 2px solid #fff;
        }

        @include focus-state;
    }

    @include focus-state;
}

@media screen and (min-width: $sm) {
    .banner ~ .banner-tabs {
        margin-top: pxToRem(-51);
        position: relative;
        z-index: 3;
     }

    .banner-tabs {
        .nav-tabs {
            margin-left: 0;
            width: auto;
        }

        .nav-item {
            flex-grow: unset;
            margin-right: pxToRem(5);
            
            &:last-child {
                margin: 0;
            }
        }
        
        .nav-link {
            padding: pxToRem(8) pxToRem(35);
            text-decoration: none;
            width: auto;
        }

        .tab-content{
            padding: pxToRem(20) 0  pxToRem(10);

            &::before {
                left: calc(((100vw / 12) * -1) - #{pxToRem(12)});
            }
        }
    }
}
