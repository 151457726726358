.header-flyout {
  background-color: $swatch-white;
  left: 0;
  left: 100vw;
  opacity: 0;
  overflow: scroll;
  padding: pxToRem(25) 0 pxToRem(75);
  position: fixed;
  top: 0;
  width: 100vw;
  display: none;
  z-index: -1;
  
  &.transition {
    transition: left 0.5s ease-in, box-shadow 0.5s ease-in;
  }
  
  &.active {
    box-shadow: pxToRem(-18) 0 pxToRem(60) pxToRem(0) rgba($swatch-black, 0.2);
    left: 0;
    opacity: 1;
    z-index: 4;
    display: block;
    
    .container-fluid {
      position: relative;
      z-index: 1;
    }
    
    .header-flyout__cover {
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 0;
    }
  }

  &__close {
    background-color: transparent;
    background-image: url('~src/modules/SVGs/close.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    height: pxToRem(18);
    margin-left: pxToRem(15);
    width: pxToRem(18);
  }
  
  &__links {
    list-style-type: none;
    margin: pxToRem(35) 0 0;
    padding: 0 pxToRem(15);
  }
  
  &__links-item {
    margin-bottom: pxToRem(10);
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__link {
    display: flex;
    color: $swatch-black;
    font-size: pxToRem(16);
    font-weight: $font-weight-semibold;
    justify-content: space-between;
    line-height: pxToRem(24);
    letter-spacing: 0;
    
    &:focus,
    &:hover {
      color: $swatch-green-darker;
      
      &::after {
        background-image: url('~src/modules/SVGs/chevron-right-green.svg');
      }
    }
    
    &::after {
      background-image: url('~src/modules/SVGs/chevron-right.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: block;
      height: pxToRem(18);
      width: pxToRem(18);
    }
  }
  
  &__bottom {
    display: flex;
    flex-wrap:wrap;
    margin: pxToRem(30) pxToRem(12) 0;
    
    a {
      display: block;
      margin-bottom: pxToRem(10);
      margin-right: pxToRem(10);
      padding: pxToRem(20);
      text-decoration: none;
      
      &:focus,
      &:hover {
        opacity: 0.8;
      }
      
      &:nth-child(odd) {
        background-color: $swatch-green-darker;
        color: $swatch-white;
      }
      
      &:nth-child(even) {
        border: pxToRem(1) solid $swatch-green-darker;
        color: $swatch-green-darker;
      }
    }
  }

    @include focus-state($swatch-black);
}

.header-flyout-search {
  overflow: hidden;
  padding-bottom: pxToRem(45);
  top: pxToRem(132);

  .header-search__form {
    align-items: flex-start;
    height: auto;
  }
  
  .tt-menu {
    border: none;
    margin-top: 0;
    padding: pxToRem(30) 0 0;
    position: relative !important;
    width: calc(100% + #{pxToRem(76)});
    
    &.tt-open {
      position: relative !important;
    }
  }
}

.header-flyout-nav {
  max-height: 100vh;
  overflow: scroll;
}

/*
 * Desktop layout
 */

 @media screen and (min-width: $lg) {
  .header-flyout {
    display: none;
  }
}
