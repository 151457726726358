.govt-tas {
    .govt-banner {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;

        &::after,
        &::before {
            content: none;
        }

        &--home {
            .govt-banner__content {
                display: flex;
                flex-direction: column-reverse;
                margin: pxToRem(40) 0;
            }

            .govt-banner__subheading {
                margin: 0 0 pxToRem(10);
                font-size: pxToRem(18);
                font-weight: $font-weight-normal;
                
                &::after {
                    content: none;
                }
                
                &:focus,
                &:hover {
                    text-decoration: none;
                }
            }
        }
        
        &--landing {
            background-color: $swatch-govt-blue-2;
            padding: pxToRem(30) 0 pxToRem(40);
            
            .govt-banner__heading {
                font-size: pxToRem(24);
                line-height: 1;
                margin: 0;
            }
            
            .govt-banner__subheading {
                color: $swatch-white;
                font-size: pxToRem(14);
                line-height: pxToRem(18);
                margin: pxToRem(10) 0 0;
                
                &::after {
                    content: none;
                }
                
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
            
            ol {
                display: flex;
                margin-bottom: pxToRem(20);
            }
            
            li {
                display: none;
                color: $swatch-white;
                margin-right: pxToRem(5);
                
                &::after {
                    content: '>';
                    display: inline-block;
                    margin-left: pxToRem(5);
                }
                
                &:nth-last-child(2) {
                    display: block;
                }
                
                &:last-child::after,
                &:nth-last-child(2)::after {
                    content: none;
                }
                
                &:last-child a {
                    font-weight: $font-weight-normal;
                    pointer-events: none;
                    text-decoration: none;
                }
            }
            
            a {
                color: $swatch-white;
                font-size: pxToRem(14);
                font-weight: $font-weight-semibold;
            }
        }
        
        &__content {
            background-color: rgba($swatch-govt-blue-2, 90%);
            margin: pxToRem(45) 0;
            padding: pxToRem(45) pxToRem(35) pxToRem(35) 0;
        }

        &__content::before {
            background-color: rgba($swatch-govt-blue-2, 90%);
        }
        
        &__heading {
            color: $swatch-white;
            font-size: pxToRem(24);
            font-weight: $font-weight-semibold;
            line-height: pxToRem(26);
            letter-spacing: 0;
            text-align: left;            
        }
        
        &__subheading {
            color: $swatch-white;
            display: block;
            font-size: pxToRem(14);
            font-weight: $font-weight-semibold;
            line-height: pxToRem(18);
            letter-spacing: 0;
            margin-top: pxToRem(12);
            text-align: left;
            text-decoration: none;
            
            &:hover,
            &:focus {
                color: $swatch-white;
                text-decoration: underline;
            }

            &::after {
                background-image: url($icon-path + 'chevron-right-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                display: inline-block;
                height: pxToRem(13);
                margin-left: pxToRem(15);
                vertical-align: middle;
                width: pxToRem(10);
            }
        }
    }
    
    .govt-pills-banner {
        background-color: $swatch-govt-blue-3;
        padding: pxToRem(35) 0 pxToRem(50);
        
        &.govt-pills-banner--inverse {
            background-color: $swatch-white;
            padding: pxToRem(20) 0 pxToRem(50);
            
            .govt-pills-banner__heading {
                display: none;
            }

            a {
                color: $swatch-white;
            }

            a:focus,
            a:hover {
                border-color: $swatch-govt-blue-3;
            }
            
            ul {
                margin-top: 0;
            }
        }

        &__heading {
            color: $swatch-white;
            font-size: pxToRem(18);
            font-weight: $font-weight-semibold;
            line-height: pxToRem(30);
            letter-spacing: 0;
            text-align: left;
        }
        
        ul {
            margin-top: pxToRem(30);
        }
        
        li {
            margin: 0 pxToRem(10) pxToRem(10) 0;
            
            &:last-child {
                margin-right: 0;
            }
        }
        
        a {
            background-color: $swatch-govt-blue-3;
            border: pxToRem(1) solid $swatch-white;
            border-radius: pxToRem(32);
            color: $swatch-white;
            font-size: pxToRem(14);
            font-weight: $font-weight-semibold;
            display: block;
            letter-spacing: 0;
            line-height: pxToRem(30);
            padding: pxToRem(10) pxToRem(15);
            text-align: center;
            text-decoration: none;
            transition:
                0.2s background-color ease,
                0.2s color ease
            ;
            
            &:hover,
            &:focus {
                background-color: $swatch-white;
                color: $swatch-govt-blue-2;
                text-decoration: none;
            }
        }
    }
}

@media screen and (min-width: $md) {
    .govt-tas {
        .govt-banner {
            &--home {
                .govt-banner__content {
                    margin: pxToRem(70) 0;
                    padding: pxToRem(30) pxToRem(70) pxToRem(30) 0;
                }

                .govt-banner__heading {
                    font-size: pxToRem(42);
                    line-height: pxToRem(48);
                    letter-spacing: 0;                    
                }

                .govt-banner__subheading {
                    font-size: pxToRem(28);
                    margin-bottom: pxToRem(20);
                }
            }
            
            &--landing {
                padding: pxToRem(35) 0 pxToRem(65);

                .govt-banner__heading {
                    font-size: pxToRem(42);
                }
                
                .govt-banner__subheading {
                    font-size: pxToRem(16);
                    margin-top: pxToRem(15);
                }
                
                li {
                    display: block;
                    
                    &:nth-last-child(2)::after {
                        content: '>';
                    }
                }
            }

            &__content {
                background-color: transparent;
                margin: pxToRem(80) 0;
                padding: pxToRem(50) pxToRem(40) pxToRem(45) 0;
            }
            
            &__heading {
                font-size: pxToRem(42);
                line-height: pxToRem(48);
            }
            
            &__subheading {
                font-size: pxToRem(16);
                margin-top: pxToRem(15);
            }
        }
        
        .govt-pills-banner {
            padding: pxToRem(65) 0 pxToRem(60);
            
            &.govt-pills-banner--inverse {
                padding: pxToRem(20) 0 pxToRem(50);

                ul {
                    margin-top: 0;
                }
            }
            
            &__heading {
                font-size: pxToRem(30);
                font-weight: $font-weight-semibold;
                line-height: pxToRem(30);
                letter-spacing: 0;
                text-align: left;
            }
            
            ul {
                margin-top: pxToRem(45);
            }
            
            a {
                font-size: pxToRem(16);
            }
        }
    }
}