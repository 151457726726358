.feedback-form {
    margin-top: pxToRem(30);

    &::before {
        background-color: rgba($swatch-black, 0.3);
        content: '';
        display: block;
        height: pxToRem(1);
        margin: 0 pxToRem(16) pxToRem(30);
        width: calc(100% - #{pxToRem(32)});
    }
    
    &__inner {
        padding: 0 pxToRem(12);
    }

    &__heading {
        color: $swatch-grey-dark;
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        letter-spacing: 0;
        line-height: pxToRem(30);
        margin-bottom: pxToRem(16);
    }

    &__hidden {
        display: none;
    }

    &__radios {
        display: flex;
        
        label {
            padding-top: pxToRem(2);
        }
    }
    
    &__radio {
        display: flex;
        margin-right: pxToRem(30);
        
        &:last-child {
            margin-right: 0;
        }
    }
    
    label {
        margin: 0 0 0 pxToRem(10);
        transform: translateY(#{pxToRem(-2)});
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 20px;

        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        border: solid 1px #bbbbbb;
        background: #ffffff;
        padding: 11px 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    [type="radio"] {
        margin: 0;
        position: relative;
        
        &:hover,
        &:active,
        &:focus {
            box-shadow: none;
        }

        &::before {
            background-color: $swatch-white;
            border: pxToRem(1) solid $swatch-grey-dark;
            border-radius: 50%;
            content: '';
            display: block;
            height: pxToRem(22);
            max-height: pxToRem(22);
            max-width: pxToRem(22);
            position: absolute;
            transform: translate(#{pxToRem(-1)}, 0);
            width: pxToRem(22);
        }
        
        &:checked::after {
            background-color: $swatch-green-light;
            border-radius: 50%;
            content: '';
            display: block;
            height: pxToRem(14);
            position: absolute;
            transform: translate(#{pxToRem(3)}, #{pxToRem(4)});
            width: pxToRem(14);
            z-index: 1;
        }
    }

    .feedback-form__query {
        label {
            margin: 0;
        }
    }

    .feedback-form__hidden {
        display: none;
    }

    .sq-form-submit {
        background-color: $swatch-green-dark;
        border: 0;
        color: $swatch-white;
        display: block;
        float: right;
        font-weight: $font-weight-semibold;
        letter-spacing: 0;
        line-height: pxToRem(18);
        margin-top: pxToRem(30);
        padding: pxToRem(12) pxToRem(15);
        text-align: center;
        text-decoration: none;

        &:focus,
        &:hover {
            color: $swatch-white;
            opacity: 0.8;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .feedback-form {
        &__inner {
            padding: 0;
        }
        
        &__heading {
            font-size: pxToRem(18);
        }

        &::before {
            margin-bottom: pxToRem(20);
            transform: translateX(calc(((100% / 12)) + #{pxToRem(15)}));
            width: calc(((100% / 12) * 10) - #{pxToRem(25)});
        }
        
        [type="radio"]:checked::after {
            background-color: $swatch-green-darker;
        }
    }
}
