.alert {
    background-color: rgba($swatch-red-alert, 0.05);
    border: 0;
    border-bottom: 1px solid $swatch-red-alert;
    border-top: 1px solid $swatch-red-alert;
    border-radius: 0;
    margin-bottom: 0;
    padding: pxToRem(10) 0;

    &__top {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
    
    &__icon {
        background-image: url('~src/modules/SVGs/alert.svg');
        background-position: center;
        background-size: cover;
        display: block;
        height: pxToRem(31);
        width: pxToRem(31);
    }
    
    &__heading {
        color: $swatch-grey-dark;
        font-size: pxToRem(15);
        font-weight: 700;
        line-height: pxToRem(20);
        letter-spacing: 0;
        margin-bottom: 0;
    }
    
    &__toggle {
        background-color: transparent;
        border: 0;
        background-image: url('~src/modules/SVGs/chevron-down.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: pxToRem(16) pxToRem(8);
        height: pxToRem(16);
        width: pxToRem(16);
        
        &.active {
            transform: rotate(180deg);
        }
    }
    
    &__content {
        display: none;
        height: 0;
        overflow: hidden;
        padding: pxToRem(20) 0;
        
        * {
            color: $swatch-black;
            font-size: pxToRem(16);
            line-height: pxToRem(22);
            letter-spacing: 0;
        }
        
        ul:last-child {
            margin: 0;
        }
        
        li {
            margin-bottom: pxToRem(20);
            
            &:last-child {
                margin-bottom: 0;
            }
        }
        
        &.active {
            display: block;
            height: auto;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .alert {
        &__top {
            justify-content: center;
        }
        
        &__heading {
            margin: 0 pxToRem(25);
        }
    }
}
