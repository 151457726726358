.related-information {
    margin: pxToRem(35) 0 pxToRem(40);

    &__content {
        border: pxToRem(1) solid $swatch-green-dark;
        border-radius: pxToRem(5);
        padding: pxToRem(30) pxToRem(30) pxToRem(40);
    }
    
    h4 {
        font-size: pxToRem(16);
        font-weight: $font-weight-bold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        margin-bottom: 15px;
    }
    
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    li {
        margin-bottom: pxToRem(10);
        
        &:last-child {
            margin-bottom: 0;
        }
    }
    
    li a {
        color: $swatch-grey-light;
        font-size: pxToRem(16);
        font-weight: $font-weight-normal;
        line-height: pxToRem(22);
        letter-spacing: 0;
        
        &:focus,
        &:hover {
            color: $swatch-green-dark;
        }
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .related-information {
        margin: pxToRem(50) 0 pxToRem(55);

        &__content {
            padding: pxToRem(30) pxToRem(45);
        }
        
        h4,
        li a {
            font-size: pxToRem(18);
        }
    }
}
