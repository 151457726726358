.govt-tas {
    .page-wrapper > .main > .container-fluid > .row > .col-12 > .row > .col-12 > .services-accordions:first-child {
        padding-top: 0;
    }
    
    .services-accordion {
        border: none;
        margin-bottom: pxToRem(10);
        
        &.active {
            border: pxToRem(1) solid $swatch-govt-blue-3;
            
            .services-accordion__heading,
            .services-accordion__heading::before {
                border: none;
                color: $swatch-govt-blue-3;
            }
            
            .services-accordion__heading::after {
                background-image: url($icon-path + 'chevron-down-blue.svg');
            }
        }
    }
    
    .services-accordion__heading {
        border: pxToRem(1) solid $swatch-grey-lightest;
        color: $swatch-govt-blue-3;
        
        &::after {
            background-image: url($icon-path + 'chevron-down-blue.svg');
        }
    }

    .services-accordion-dropdown__content {
        border-color: $swatch-govt-blue-3;
    }

    .services-accordion-dropdown__description {
        padding: 0 pxToRem(20) pxToRem(30);
        
        p,
        a {
            font-size: pxToRem(16);
            line-height: pxToRem(21);
            letter-spacing: 0;
            text-align: left;
        }

        a {
            color: $swatch-govt-blue-3;
            font-weight: $font-weight-semibold;
            
            &::after {
                content: '';
                display: inline-block;
                background-image: url($icon-path + 'chevron-down-blue.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                height: pxToRem(8);
                margin-left: pxToRem(13);
                transform: rotate(-90deg);
                vertical-align: middle;
                width: pxToRem(13);
            }
        }
    }
}

@media screen and (min-width: $md) {
    .govt-tas {
        .services-accordion.active {
            border: none;
            
            .services-accordion__heading,
            .services-accordion__heading::before {
                border: pxToRem(1) solid $swatch-govt-blue-3;
            }
            
            .services-accordion__heading::before {
                border-bottom-color: $swatch-white;
                border-top: none;
                bottom: pxToRem(-26);
            }
        }
        
        .services-accordion__heading {
            &:hover {
                background-color: $swatch-govt-blue-3;
                border-color: $swatch-govt-blue-3;
                color: $swatch-white;
                
                &::after {
                    background-image: url($icon-path + 'chevron-down-white.svg');
                }
            }
            
            &:focus {
                background-color: $swatch-white;
                color: $swatch-govt-blue-3;
            }
        }
        
        .services-accordion-dropdown__description {
            padding: pxToRem(40) pxToRem(30);
        }
    }
}