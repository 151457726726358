.services-accordions {
    padding: pxToRem(40) 0 pxToRem(70);
    position: relative;
    
    &::after {
        background-color: $swatch-white;
        bottom: pxToRem(70);
        content: '';
        height: pxToRem(5);
        left: 0;
        position: absolute;
        width: 100%;
    }

    @include focus-state($swatch-black);
}

@media screen and (min-width: $md) {
    .services-accordions {
        padding: pxToRem(120) 0 pxToRem(80);
    }
}
