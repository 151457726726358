body.govt-tas .govt-cta-cards {
    padding: pxToRem(40) 0 pxToRem(20);
    
    &__heading {
        color: $swatch-govt-blue-3;
        font-size: pxToRem(24);
        font-weight: $font-weight-semibold;
        line-height: pxToRem(29);
        letter-spacing: 0;
        margin-bottom: 0;
        text-align: left;
    }
    
    &__panels {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: pxToRem(35);
        padding: 0;
    }
    
    &__panel {
        border: pxToRem(1) solid $swatch-grey-lightest;
        margin-top: pxToRem(10);
        width: 100%;
        
        &:first-child {
            margin-top: 0;
        }
    }

    a {
        display: block;
        height: 100%;
        padding: pxToRem(20);
        text-decoration: none;
        width: 100%;
        
        &:hover,
        &:focus {
            background-color: $swatch-govt-blue-3;
            transition: 0.2s background-color ease-in;

            * {
                color: $swatch-white;
            }
            
            .govt-cta-cards__panel-icon--covid::before {
                background-image: url($icon-path + 'covid-19-white.svg');
            }
            
            .govt-cta-cards__panel-icon--tas-alert::before {
                background-image: url($icon-path + 'tas-alert-white.svg');
            }
            
            .govt-cta-cards__panel-icon--gov::before {
                background-image: url($icon-path + 'tasmania-govt.svg');
            }
            
            .govt-cta-cards__panel-icon--gov span {
                border-left-color: $swatch-white;
            }
            
            .govt-cta-cards__panel-icon > span::after {
                background-image: url($icon-path + 'chevron-right-white.svg');
            }
        }
    }

    &__panel-icon {
        align-items: stretch;
        color: $swatch-govt-grey-1;
        display: flex;
        font-size: pxToRem(28);
        font-weight: $font-weight-normal;
        line-height: pxToRem(30);
        letter-spacing: 0;
        text-align: left;

        &::before {
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: pxToRem(50);
            display: block;
            margin-right: pxToRem(10);
            width: pxToRem(50);
        }

        & > span {
            align-items: center;
            display: flex;
            justify-content: space-between;
            width: 100%;

            &::after {
                background-image: url($icon-path + 'chevron-right-blue.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                display: block;
                height: pxToRem(13);
                margin-left: pxToRem(15);
                width: pxToRem(8);
            }
        }
        
        &--gov {
            &::before {
                background-image: url($icon-path + 'tasmania-govt-black.svg');
                content: '';
            }

            span {
                border-left: pxToRem(2) solid $swatch-black;
                padding-left: pxToRem(10);
            }
        }

        &--covid::before {
            background-image: url($icon-path + 'covid-19.svg');
            content: '';
        }
        
        &--tas-alert::before {
            background-image: url($icon-path + 'tas-alert.svg');
            content: '';
        }
    }
    
    &__panel-bottom {
        margin-top: pxToRem(12);
    }
    
    &__panel-heading {
        color: $swatch-govt-blue-3;
        font-size: pxToRem(18);
        font-weight: $font-weight-semibold;
        line-height: pxToRem(30);
        letter-spacing: 0;
        margin: 0;
        text-align: left;
    }
    
    &__panel-description {
        color: $swatch-govt-grey-1;
        font-size: pxToRem(14);
        font-weight: $font-weight-normal;
        line-height: pxToRem(21);
        letter-spacing: 0;
        margin: pxToRem(5) 0 0;
        text-align: left;
    }
}

@media screen and (min-width: $md) {
    body.govt-tas .govt-cta-cards {
        &__heading {
            font-size: pxToRem(30);
        }

        &__panels {
            display: flex;
        }
        
        &__panel {
            margin-right: pxToRem(25);
            margin-top: 0;
            
            &:last-child {
                margin-right: 0;
            }
        }

        a {
            padding: pxToRem(40) pxToRem(30) pxToRem(35);
        }

        &__panel-heading {
            font-size: pxToRem(24);
        }

        &__panel-description {
            font-size: pxToRem(16);
            line-height: pxToRem(21);
            margin-top: pxToRem(20);
            text-align: left;            
        }
        
        &__panel-bottom {
            margin-top: pxToRem(22);
        }
    }
}
