.govt-tas {
    .header {
        background-color: $swatch-white;
    }

    .header__bottom {
        padding: pxToRem(12) 0;
    }
    
    .header__bottom li {
        align-items: center;
        display: flex;
    }
    
    .header-top__link {
        color: $swatch-white;
    }
    
    .header-top__link--no-redirect::before {
        content: none;
    }
}

@media screen and (min-width: $md) {
    .govt-tas {
        .header__bottom {
            padding: pxToRem(18) 0;
        }
    }
}